import { Select, MenuItem } from '@mui/material';
import Placeholder from './UI/Placeholder';

export default function PageDropdown({ pageCount, onChange, page, setPage }) {
  if (!pageCount) {
    return null;
  }

  const pageNumbers = Array.from(Array(pageCount + 1).keys());
  const onPageChange = (e) => {
    setPage(e.target.value);
    onChange(e);
  };

  return (
    <Select
      value={page}
      onChange={onPageChange}
      size='small'
      variant='outlined'
      displayEmpty
      renderValue={
        page === ''
          ? () => <Placeholder> Jump to a page</Placeholder>
          : () => <div>{page}</div>
      }
      style={{
        margin: '0 2px',
        minWidth: 160,
      }}
    >
      {pageNumbers.map((number) => {
        if (number !== 0)
          return (
            <MenuItem key={number} value={number}>
              {number}
            </MenuItem>
          );
      })}
    </Select>
  );
}
