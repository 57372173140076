import Breadcrumb from './Breadcrumb';
import './Breadcrumbs.css';

export default function Breadcrumbs({
  current,
  items,
  onChildClick,
  currentPDFDoc,
  setShowCreateObjectModal,
}) {
  return (
    <div className='breadcrumbs'>
      <span>
        <span className='caption'>Current Object:&nbsp;&nbsp;&nbsp;</span>
        {current}
      </span>
      <div className='vr'></div>
      <span className='caption'>Current Path:&nbsp;&nbsp;&nbsp;</span>
      {items.map((child) => (
        <Breadcrumb key={child.key} child={child} onClick={onChildClick} />
      ))}
      {current != 'Trailer' && currentPDFDoc && (
        <div
          className='btn clickable'
          onClick={() => setShowCreateObjectModal(true)}
        >
          + Add New Object
        </div>
      )}
    </div>
  );
}
