import React, { useEffect, useState } from 'react';
import { convertTypeToStr } from '../util';

const XRefObject = ({ object, onXRefObjectClick }) => {
  const [objectNumber, setObjectNumber] = useState(0);
  const [genNumber, setGenNumber] = useState(0);
  const [type, setType] = useState('');
  const [free, setFree] = useState(false);
  const [offset, setOffset] = useState(0);

  const clickable = !free && type != 0;
  const clickableClass = clickable ? 'clickableXref' : '';

  useEffect(() => {
    const loadXrefData = async () => {
      const isFree = await object.isFree();
      setFree(isFree);

      const objNum = await object.getObjNum();
      setObjectNumber(objNum);

      const genNum = await object.getGenNum();
      setGenNumber(genNum);

      const objOffset = await object.getOffset();
      setOffset(objOffset);
      if (!isFree) {
        const objType = await object.getType();
        setType(objType);
      }
    };
    loadXrefData();
  }, [object]);
  return (
    <div
      className={`row xref ${clickableClass}`}
      onClick={async () => {
        if (clickable) await onXRefObjectClick(object, objectNumber);
      }}
    >
      <div className='col obj-col'>{objectNumber}</div>
      <div className='col gen-col'>{genNumber}</div>
      <div className='col obj-type-col'>
        {type !== '' ? convertTypeToStr(type) : '-'}
      </div>
      <div className='col free-col'>{free ? 'free' : '-'}</div>
      <div className='col offset-col'>{offset}</div>
    </div>
  );
};

export default XRefObject;
