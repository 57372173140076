import React from 'react';
import { defaultView, previewView, xRefView } from '../../App';
import './ViewTab.css';

const ViewTab = ({ setCurrentTab, currentTab, currentPDFDoc }) => {
  const xrefFocusClass = currentTab === xRefView ? 'focus' : '';
  const trailerFocusClass = currentTab === defaultView ? 'focus' : '';
  const previewFocusClass = currentTab === previewView ? 'focus' : '';
  const disable = currentPDFDoc ? '' : 'disabled';

  return (
    <div className='tab-container'>
      <div
        className={`tab left ${trailerFocusClass} ${disable}`}
        onClick={() => {
          if (!disable) setCurrentTab(defaultView);
        }}
      >
        Trailer View
      </div>
      <div
        className={`tab center ${xrefFocusClass} ${disable}`}
        onClick={() => {
          if (!disable) setCurrentTab(xRefView);
        }}
      >
        XRefTable
      </div>
      <div
        className={`tab right ${previewFocusClass} ${disable}`}
        onClick={() => {
          if (!disable) setCurrentTab(previewView);
        }}
      >
        PDF Preview
      </div>
    </div>
  );
};

export default ViewTab;
