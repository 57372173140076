import React, { useEffect, useState } from 'react';
import { convertTypeToStr, loadChildren } from '../../util';
import './ViewModal.css';
import './Modal.css';
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Pagination,
  Skeleton,
} from '@mui/material';

import ContentCopy from '@mui/icons-material/ContentCopy';
import PDFNetObjTypes from '../../constants/PDFNetObjTypes';
import Tooltip from '../UI/Tooltip';

const MAX_CHARACTER_LENGTH_PER_PAGE = 20000;
const MIN_CHARACTER_LENGTH_ENABLE_PAGINATION = 100000;
const {
  e_stream,
  e_string,
  e_name,
  e_number,
  e_dict,
  e_array,
  e_bool,
  e_null,
} = PDFNetObjTypes;

const getValueDisplayedInPage = (initialVal, obj) => {
  if (obj.type === e_number) {
    return String(initialVal).slice(0, MAX_CHARACTER_LENGTH_PER_PAGE);
  } else if (
    obj.type === e_dict ||
    obj.type === e_array ||
    obj.type !== e_bool ||
    obj.type !== e_null
  ) {
    return '';
  } else {
    return obj.value.slice(0, MAX_CHARACTER_LENGTH_PER_PAGE);
  }
};

const ViewModal = ({
  userSelectedObject,
  onCloseClick,
  setObjectChanged,
  setUserSelectedObject,
  currentObj,
  userSelectedObjectIndex,
  isStreamFromXref,
}) => {
  const MAX_IMAGE_SIZE = 400;
  const STREAM_VIEW = 'Stream View';
  const IMAGE_VIEW = 'Image View';
  const BUFFER_SIZE = 102400; // 100kb

  const [updatedValue, setUpdatedValue] = useState(userSelectedObject.value);
  const [updatedKeyName, setUpdatedKeyName] = useState(userSelectedObject.key);
  const [checked, setChecked] = useState(userSelectedObject.value);
  const [invalidKey, setInvalidKey] = useState(false);
  const [invalidValue, setInvalidValue] = useState(false);
  const [newIndex, setNewIndex] = useState(userSelectedObjectIndex);
  const [enablePagination, setEnablePagination] = useState(true);
  const [totalPageNum, setTotalPageNum] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [valueDisplayedInPage, setValueDisplayedInPage] = useState(
    getValueDisplayedInPage(userSelectedObject.value, userSelectedObject)
  );
  const [populatedPages, setPopulatedPages] = useState([]);

  const [imageLoading, setImageLoading] = useState(true);
  const [isImage, setIsImage] = useState(false);
  const [currentStreamTab, setCurrentStreamTab] = useState(STREAM_VIEW);
  const [imageStream, setImageStream] = useState('');
  const [imageDimension, setImageDimension] = useState({ width: 0, height: 0 });
  const leftTabFocusClass = currentStreamTab === STREAM_VIEW ? 'focus' : '';
  const rightTabFocusClass = leftTabFocusClass === '' ? 'focus' : '';
  const hideStreamClass = currentStreamTab === STREAM_VIEW ? '' : 'hide';
  const hideImageClass = currentStreamTab === IMAGE_VIEW ? '' : 'hide';
  const showPaginationButtons =
    enablePagination && currentStreamTab === STREAM_VIEW;

  const limitTextAreaHeight = enablePagination || isImage;
  const limitTextAreaHeightClass = limitTextAreaHeight
    ? 'limit-text-height'
    : '';
  const objCount = currentObj.children.length;
  const indexNumbers = Array.from(Array(objCount).keys());
  const showReadOnlyMode =
    userSelectedObject.type === e_null ||
    isStreamFromXref ||
    userSelectedObject.key === 'DATA';

  const disable =
    invalidKey || invalidValue || showReadOnlyMode ? 'disabled' : '';
  const isStream = userSelectedObject.type === e_stream;
  const isFree = userSelectedObject.type === '-';

  useEffect(() => {
    const isNotDataStream = userSelectedObject.parent;
    if (isNotDataStream) {
      const valueIsRenderedInTextArea =
        userSelectedObject.type === e_name ||
        userSelectedObject.type === e_string ||
      isStream;
      if (valueIsRenderedInTextArea) {
        const isEmptyValue = updatedValue.split(' ').join('') === '';
        setInvalidValue(isEmptyValue);
      } else if (userSelectedObject.type === e_number) {
        const isValueNumber = parseInt(updatedValue, 10);
        setInvalidValue(isNaN(isValueNumber));
      } else {
        setInvalidValue(false);
      }
      if (userSelectedObject.parent.type === e_array) {
        setInvalidKey(false);
      } else {
        const isEmptyKey = updatedKeyName.split(' ').join('') === '';
        setInvalidKey(isEmptyKey);
      }
    }
  }, [updatedKeyName, updatedValue, userSelectedObject]);

  const populatePages = (maxPageNum) => {
    const arr = [];
    let valueInPage;
    for (let i = 0; i < maxPageNum; i++) {
      if (i === 0) {
        valueInPage = updatedValue.slice(0, MAX_CHARACTER_LENGTH_PER_PAGE);
      } else if (i === maxPageNum - 1) {
        valueInPage = updatedValue.slice(
          MAX_CHARACTER_LENGTH_PER_PAGE * i,
          updatedValue.length + 1
        );
      } else {
        valueInPage = updatedValue.slice(
          MAX_CHARACTER_LENGTH_PER_PAGE * i,
          MAX_CHARACTER_LENGTH_PER_PAGE * (i + 1)
        );
      }
      arr.push(valueInPage);
    }
    setPopulatedPages(arr);
  };

  useEffect(() => {
    const displayVal = populatedPages.at(currentPage - 1);
    setValueDisplayedInPage(displayVal);
  }, [currentPage, populatedPages]);

  useEffect(() => {
    const doNotRenderTextArea =
      userSelectedObject.type !== e_array &&
      userSelectedObject.type !== e_dict &&
      userSelectedObject.type !== e_bool &&
      userSelectedObject.type !== e_null &&
      userSelectedObject.value;
    if (doNotRenderTextArea) {
      const isPaginationEnabled =
        userSelectedObject.value.length >=
        MIN_CHARACTER_LENGTH_ENABLE_PAGINATION;
      setEnablePagination(isPaginationEnabled);
      if (isPaginationEnabled) {
        const filledPages =
          userSelectedObject.value.length / MAX_CHARACTER_LENGTH_PER_PAGE;
        if (
          userSelectedObject.value.length % MAX_CHARACTER_LENGTH_PER_PAGE !==
          0
        ) {
          const pageNum = Math.ceil(filledPages);
          setTotalPageNum(pageNum);
          populatePages(pageNum);
        } else {
          setTotalPageNum(filledPages);
          populatePages(filledPages);
        }
      }
      setValueDisplayedInPage(populatedPages[0]);
    } else {
      setEnablePagination(false);
    }
  }, []);

  const changeValue = async (e) => {
    if (enablePagination) {
      const newPageValue = e.target.value;
      setValueDisplayedInPage(newPageValue);
      const populatedPagesCopy = populatedPages;
      populatedPagesCopy[currentPage - 1] = newPageValue;

      setPopulatedPages(populatedPagesCopy);
      const newValue = populatedPages.join('');
      setUpdatedValue(newValue);
    } else {
      const newValue = e.target.value;
      setUpdatedValue(newValue);
    }
  };

  const changeKeyName = async (e) => {
    const newValue = e.target.value;
    setUpdatedKeyName(newValue);
  };

  const updateValue = async () => {
    const updatedObj = await userSelectedObject.objWrapper.setValue(
      updatedValue,
      checked
    );
    await userSelectedObject.objWrapper.setIndex(
      currentObj,
      userSelectedObjectIndex,
      newIndex,
      updatedObj
    );

    userSelectedObject.value = updatedValue;
    setObjectChanged(userSelectedObject);
  };

  const updateKeyName = async () => {
    const hasKeys =
      userSelectedObject.parent.type === e_dict ||
      userSelectedObject.parent.type === e_stream;
    if (hasKeys) {
      await userSelectedObject.parent.obj.rename(
        userSelectedObject.key,
        updatedKeyName
      );

      userSelectedObject.key = updatedKeyName;
      setObjectChanged(userSelectedObject);
    }
  };

  const onSaveClick = async () => {
    await updateValue();
    await updateKeyName();
    onCloseClick();
    setUserSelectedObject(null);
  };

  const copyTextToClipBoard = async () => {
    await navigator.clipboard.writeText(updatedValue);
  };

  const renderValue = () => {
    if (isFree) return;
    const cannotEditValueDirectly =
      userSelectedObject.type === e_dict || userSelectedObject.type === e_array;
    if (cannotEditValueDirectly) {
      return (
        <div className='msg'>
          Values of dictionaries and arrays must be edited by modifying their
          children
        </div>
      );
    }
    if (showReadOnlyMode) {
      return (
        <textarea
          className='modal-textarea view-textarea read-only'
          value={userSelectedObject.value}
          readOnly
        />
      );
    }
    if (userSelectedObject.type === e_bool) {
      return (
        <Checkbox
          checked={checked}
          onChange={async () => {
            setChecked(!checked);
          }}
          style={{
            color: '#14213d',
          }}
          sx={{ mt: 8 }}
        />
      );
    }
    return (
      <div>
        <Tooltip content='copy to clipboard'>
          <button
            className='clipboard-icon'
            style={{ top: isImage || enablePagination ? '22%' : '18%' }}
          >
            <ContentCopy onClick={copyTextToClipBoard} />
          </button>
        </Tooltip>
        <textarea
          className={`modal-textarea view-textarea ${limitTextAreaHeightClass}`}
          onChange={changeValue}
          value={enablePagination ? valueDisplayedInPage : updatedValue}
        />
      </div>
    );
  };

  const renderKeyOrIndex = () => {
    if (userSelectedObject.parent === null) {
      return;
    }
    const showIndexDropdown = userSelectedObject.parent.type === e_array;
    if (showIndexDropdown) {
      return (
        <FormControl sx={{ mt: 2 }}>
          <Select
            value={newIndex}
            onChange={(e) => setNewIndex(e.target.value)}
            size='small'
            defaultValue=''
            style={{
              minWidth: 80,
              maxWidth: 120,
              display: 'block',
            }}
          >
            {indexNumbers.map((index) => (
              <MenuItem key={index} value={index}>
                {index}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    const showReadOnlyInput = userSelectedObject.type === e_null;
    if (showReadOnlyInput) {
      return (
        <input
          type='text'
          className='keyname-input read-only'
          value={userSelectedObject.key}
          readOnly
        />
      );
    }
    return (
      <input
        type='text'
        className='keyname-input'
        value={updatedKeyName}
        onChange={changeKeyName}
      />
    );
  };

  useEffect(async () => {
    if (isStream) {
      const isStreamImage = await checkIsImage();
      setIsImage(isStreamImage);
    }
  }, []);

  useEffect(() => {
    if (
      isStream &&
      isImage &&
      imageStream === '' &&
      currentStreamTab === IMAGE_VIEW
    ) {
      extractImage();
    }
  }, [userSelectedObject.type, isImage, imageStream, currentStreamTab]);

  const checkIsImage = async () => {
    const children = await loadChildren(userSelectedObject);
    userSelectedObject.children = children;

    const subtype = children.filter((child) => child.key === 'Subtype');
    const imgWidth = children.filter((child) => child.key === 'Width');
    const imgHeight = children.filter((child) => child.key === 'Height');

    const isValidImage =
      subtype[0] &&
      subtype[0].value === 'Image' &&
      imgWidth.length === 1 &&
      imgHeight.length === 1;
    return isValidImage;
  };

  const resizeIfMaxSizeReached = (width, height) => {
    const dimensionRatio = height / width;
    let resizedWidth = width;
    let resizedHeight = height;
    if (
      (width > MAX_IMAGE_SIZE && width >= height) ||
      (height > MAX_IMAGE_SIZE && height < width)
    ) {
      resizedWidth = MAX_IMAGE_SIZE;
      resizedHeight = MAX_IMAGE_SIZE * dimensionRatio;
    } else if (
      (height > MAX_IMAGE_SIZE && height >= width) ||
      (width > MAX_IMAGE_SIZE && height < width)
    ) {
      resizedHeight = MAX_IMAGE_SIZE;
      resizedWidth = MAX_IMAGE_SIZE * dimensionRatio;
    }
    return { resizedWidth, resizedHeight };
  };

  const getImageDimensions = () => {
    const widthObj = userSelectedObject.children.filter(
      (child) => child.key === 'Width'
    );
    let width = widthObj[0].value;

    const heightObj = userSelectedObject.children.filter(
      (child) => child.key === 'Height'
    );
    let height = heightObj[0].value;

    const { resizedWidth, resizedHeight } = resizeIfMaxSizeReached(
      width,
      height
    );
    width = resizedWidth;
    height = resizedHeight;
    setImageDimension({ width, height });
  };

  const extractImage = async () => {
    const image = await window.PDFNet.Image.createFromObj(
      await userSelectedObject.obj
    );
    const mf = await window.PDFNet.Filter.createMemoryFilter(
      BUFFER_SIZE,
      false
    );
    const filterWriter = await window.PDFNet.FilterWriter.create(mf);
    await image.exportAsPngFromStream(filterWriter);
    await mf.memoryFilterSetAsInputFilter();
    const filterReader = await window.PDFNet.FilterReader.create(mf);
    const uint8array = await filterReader.readAllIntoBuffer();
    const src = URL.createObjectURL(
      new Blob([uint8array.buffer], { type: 'image/png' })
    );

    getImageDimensions();
    setImageStream(src);
  };

  const renderImage = () => {
    return (
      <React.Fragment>
        <Skeleton
          width={400}
          height={600}
          style={{
            margin: 'auto',
            marginTop: 20,
            display: imageLoading && isImage ? 'block' : 'none',
          }}
        />
        <img
          className={`obj-image ${hideImageClass}`}
          src={imageStream}
          style={{
            width: imageDimension.width,
            height: imageDimension.height,
            display: imageLoading ? 'none' : 'block',
          }}
          onLoad={() => {
            setImageLoading(false);
          }}
        />
      </React.Fragment>
    );
  };

  const renderImageTabs = () => {
    return (
      <div className='img-tab-container'>
        <div
          className={`img-tab left ${leftTabFocusClass}`}
          onClick={() => {
            setCurrentStreamTab(STREAM_VIEW);
          }}
        >
          Stream View
        </div>
        <div
          className={`img-tab right ${rightTabFocusClass}`}
          onClick={() => {
            setCurrentStreamTab(IMAGE_VIEW);
          }}
        >
          Image View
        </div>
      </div>
    );
  };

  return (
    <div className='modal-wrapper'>
      <div className='modal view-modal'>
        <div className='object-container'>
          {!isFree && (
            <div className='type-tag'>
              {convertTypeToStr(userSelectedObject.type)}
            </div>
          )}

          {renderKeyOrIndex()}
          {isImage && renderImageTabs()}
          {isImage && renderImage()}
          <div className={`${hideStreamClass}`}>{renderValue()}</div>
        </div>
        {showPaginationButtons && (
          <div className='page-btn'>
            <Pagination
              count={totalPageNum}
              onChange={(e, page) => setCurrentPage(page)}
            />
          </div>
        )}
        <div className='button-container view-button-container'>
          <button
            className={`btn-primary ${disable}`}
            onClick={() => {
              if (!disable) onSaveClick();
            }}
          >
            Save
          </button>
          <button className='btn-secondary' onClick={onCloseClick}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewModal;
