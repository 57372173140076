import PDFNetObjTypes from '../constants/PDFNetObjTypes';
const {
  e_dict,
  e_array,
  e_number,
  e_bool,
  e_string,
  e_stream,
  e_name,
  e_null,
} = PDFNetObjTypes;

class PDFObject {
  constructor(obj, type) {
    this.obj = obj;
    this.type = type;
  }

  async createNewStream(selectedFile, currentPDFDoc) {
    const buffer = await selectedFile.arrayBuffer();
    const uint8array = new Uint8Array(buffer);

    const newStream = await currentPDFDoc.createIndirectStream(
      uint8array,
      await window.PDFNet.Filter.createFlateEncode(
        new window.PDFNet.Filter('0')
      )
    );
    return newStream;
  }

  async addToArray(value, selectedFile, currentPDFDoc, childType, newIndex) {
    let newObject;
    switch (childType) {
      case e_dict:
        newObject = await this.obj.insertDict(newIndex);
        break;
      case e_array:
        newObject = await this.obj.insertArray(newIndex);
        break;
      case e_stream:
        const newStream = await this.createNewStream(
          selectedFile,
          currentPDFDoc
        );
        newObject = await this.obj.insert(newIndex, newStream);

        break;
      case e_string:
        newObject = await this.obj.insertString(newIndex, value);
        break;
      case e_name:
        newObject = await this.obj.insertName(newIndex, value);
        break;
      case e_number:
        newObject = await this.obj.insertNumber(newIndex, parseInt(value, 10));
        break;
      case e_bool:
        newObject = await this.obj.insertBool(newIndex, value === true);
        break;
      case e_null:
        newObject = await this.obj.insertNull(newIndex);
        break;
      default:
        break;
    }
    return newObject;
  }

  async addToDict(key, value, selectedFile, currentPDFDoc, childType) {
    let newObject;
    switch (childType) {
      case e_dict:
        newObject = await this.obj.putDict(key);
        break;
      case e_array:
        newObject = await this.obj.putArray(key);
        break;
      case e_stream:
        const newStream = await this.createNewStream(
          selectedFile,
          currentPDFDoc
        );
        newObject = await this.obj.put(key, newStream);
        break;
      case e_string:
        newObject = await this.obj.putString(key, value);
        break;
      case e_name:
        newObject = await this.obj.putName(key, value);
        break;
      case e_number:
        newObject = await this.obj.putNumber(key, parseInt(value, 10));
        break;
      case e_bool:
        newObject = await this.obj.putBool(key, value === true);
        break;
      case e_null:
        newObject = await this.obj.putNull(key);
        break;
      default:
        break;
    }
    return newObject;
  }

  async setValue(newValue, checked) {
    if (this.type === e_stream) {
      const uint8array = new TextEncoder().encode(newValue);
      await this.obj.setStreamData(uint8array);
    } else if (this.type === e_string) {
      await this.obj.setString(newValue);
    } else if (this.type === e_name) {
      await this.obj.setName(newValue);
    } else if (this.type === e_number) {
      const newNumValue = parseInt(newValue, 10);
      if (!isNaN(newNumValue)) {
        await this.obj.setNumber(newNumValue);
      }
    } else if (this.type === e_bool) {
      await this.obj.setBool(checked === true);
    }
    return this.obj;
  }

  async insertObject(newIndex, newObj, userSelectedObjectIndex, currentObj) {
    await currentObj.obj.eraseAt(userSelectedObjectIndex);
    await currentObj.obj.insert(newIndex, newObj);
  }

  async setIndex(currentObj, userSelectedObjectIndex, newIndex, updatedObj) {
    if (currentObj.type === e_array) {
      let updateIndexObj;

      switch (this.type) {
        case e_dict:
          if (await updatedObj.isIndirect()) {
            await this.insertObject(
              newIndex,
              updatedObj,
              userSelectedObjectIndex,
              currentObj
            );
          } else {
            await currentObj.obj.eraseAt(userSelectedObjectIndex);
            await currentObj.obj.insertDict(newIndex);
          }
          break;
        case e_array:
          if (await updatedObj.isIndirect()) {
            await this.insertObject(
              newIndex,
              updatedObj,
              userSelectedObjectIndex,
              currentObj
            );
          } else {
            await currentObj.obj.eraseAt(userSelectedObjectIndex);
            await currentObj.obj.insertArray(newIndex);
          }
          break;
        case e_stream:
          await this.insertObject(
            newIndex,
            updatedObj,
            userSelectedObjectIndex,
            currentObj
          );
          break;
        case e_string:
          updateIndexObj = await updatedObj.getAsPDFText();
          await currentObj.obj.eraseAt(userSelectedObjectIndex);
          await currentObj.obj.insertString(newIndex, updateIndexObj);
          break;
        case e_name:
          updateIndexObj = await updatedObj.getName();
          await currentObj.obj.eraseAt(userSelectedObjectIndex);
          await currentObj.obj.insertName(newIndex, updateIndexObj);
          break;
        case e_number:
          const numVal = await updatedObj.getNumber();
          await currentObj.obj.eraseAt(userSelectedObjectIndex);
          await currentObj.obj.insertNumber(newIndex, numVal);
          break;
        case e_bool:
          const boolVal = await updatedObj.getBool();
          await currentObj.obj.eraseAt(userSelectedObjectIndex);
          await currentObj.obj.insertBool(newIndex, boolVal);
          break;
        case e_null:
          await currentObj.obj.eraseAt(userSelectedObjectIndex);
          await currentObj.obj.insertNull(newIndex);
          break;
        default:
          break;
      }
    }
  }

  async getValue() {
    let value;
    if (this.type === e_null) {
      value = null;
    } else if (this.type === e_bool) {
      value = await this.obj.getBool();
    } else if (this.type === e_number) {
      value = await this.obj.getNumber();
    } else if (this.type === e_name) {
      value = await this.obj.getName();
    } else if (this.type === e_string) {
      value = await this.obj.getAsPDFText();
    } else if (this.type === e_dict && this.type === e_array) {
      // TODO Link child object
    } else if (this.type === e_stream) {
      const filter = await this.obj.getDecodedStream();
      const filterReader = await window.PDFNet.FilterReader.create(filter);
      const intArr = await filterReader.readAllIntoBuffer();
      value = new TextDecoder().decode(intArr);
    }
    return value;
  }
}

export default PDFObject;
