import React from 'react';
import { createRef } from 'react';
const AddStreamButton = ({ children, onFileSelect }) => {
  const filePickerRef = createRef();

  return (
    <div>
      <input
        ref={filePickerRef}
        hidden={true}
        type='file'
        onChange={onFileSelect}
      />
      <div
        className='btn clickable'
        id='add-stream'
        onClick={() => {
          filePickerRef.current.click();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default AddStreamButton;
