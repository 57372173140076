import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: '#6e6e6e',
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default Placeholder;
