import React from 'react';
import './MobileNav.css';
import DownloadIcon from '@mui/icons-material/Download';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';

const MobileNav = ({
  onOpenClick,
  onDownloadClick,
  onBackClick,
  onNextClick,
  onSettingsClick,
}) => {
  return (
    <div className='mobile-nav-container'>
      <div className='nav-btn'>
        <Button
          variant='contained'
          startIcon={<FileOpenIcon style={{ transform: 'scale(1.1)' }} />}
          style={{
            backgroundColor: 'transparent',
          }}
          onClick={onOpenClick}
        />
      </div>
      <div className='nav-btn'>
        <Button
          variant='contained'
          startIcon={<ChevronLeftIcon className='svg_icons' />}
          style={{
            backgroundColor: 'transparent',
          }}
          onClick={onBackClick}
        />
      </div>
      <div className='nav-btn'>
        <Button
          variant='contained'
          startIcon={<ChevronRightIcon className='svg_icons' />}
          style={{
            backgroundColor: 'transparent',
          }}
          onClick={onNextClick}
        />
      </div>
      <div className='nav-btn'>
        <Button
          variant='contained'
          startIcon={<DownloadIcon style={{ transform: 'scale(1.3)' }} />}
          style={{
            backgroundColor: 'transparent',
          }}
          onClick={onDownloadClick}
        />
      </div>
      <div className='nav-btn'>
        <Button
          variant='contained'
          startIcon={<SettingsIcon style={{ transform: 'scale(1.3)' }} />}
          style={{
            backgroundColor: 'transparent',
          }}
          onClick={onSettingsClick}
        />
      </div>
    </div>
  );
};

export default MobileNav;
