import React from 'react';
import { Checkbox } from '@mui/material';

const AddPasswordContainer = ({
  value,
  checked,
  onCheckboxChange,
  onInputChange,
  checkboxDisabled,
  inputDisabled,
  label,
  inputText,
}) => {
  return (
    <div className='choice-container'>
      <Checkbox
        checked={checked}
        onChange={onCheckboxChange}
        disabled={checkboxDisabled}
        style={{
          color: '#14213d',
        }}
      />
      {label}
      <br />
      <div className='input-container'>
        {inputText}
        <input
          className='password-input'
          type='password'
          disabled={inputDisabled}
          value={value}
          onChange={onInputChange}
        />
      </div>
    </div>
  );
};

export default AddPasswordContainer;
