import React, { useState } from 'react';
import './SecuritySettingsModal.css';
import AddPasswordContainer from '../UI/AddPasswordContainer';

const SecuritySettingsModal = ({
  onCloseClick,
  currentPDFDoc,
  setShowSettingsModal,
  isOwner,
}) => {
  const [userPasswordValue, setUserPasswordValue] = useState('');
  const [masterPasswordValue, setMasterPasswordValue] = useState('');
  const [userPasswordChecked, setUserPasswordChecked] = useState(false);
  const [masterPasswordChecked, setMasterPasswordChecked] = useState(false);
  const [userPasswordInputDisabled, setUserPasswordInputDisabled] =
    useState(true);
  const [masterPasswordInputDisabled, setMasterPasswordInputDisabled] =
    useState(true);

  const onSaveClick = async () => {
    const newHandler = await window.PDFNet.SecurityHandler.createDefault();
    if (userPasswordChecked && userPasswordValue.length > 0) {
      await newHandler.changeUserPasswordUString(userPasswordValue);
    }
    if (masterPasswordChecked && masterPasswordValue.length > 0) {
      await newHandler.changeMasterPasswordUString(masterPasswordValue);
    }
    await currentPDFDoc.setSecurityHandler(newHandler);
    setShowSettingsModal(false);
  };

  return (
    <div className='modal-wrapper'>
      <div className='modal security-modal'>
        <div className='title'>Document Security Settings</div>
        <div className='paddingContainer settings'>
          {isOwner ? (
            <div>This document has no security settings added</div>
          ) : (
            <div>You do not have the permission to change passwords</div>
          )}
          <AddPasswordContainer
            label='Password required to open document'
            inputText='User password'
            checked={userPasswordChecked}
            value={userPasswordValue}
            checkboxDisabled={!isOwner}
            inputDisabled={userPasswordInputDisabled}
            onCheckboxChange={() => {
              setUserPasswordChecked(!userPasswordChecked);
              setUserPasswordInputDisabled(userPasswordChecked);
            }}
            onInputChange={(e) => setUserPasswordValue(e.target.value)}
          />
          <AddPasswordContainer
            label='Password required to change permissions and passwords'
            inputText='Master password'
            checked={masterPasswordChecked}
            value={masterPasswordValue}
            checkboxDisabled={!isOwner || !userPasswordChecked}
            inputDisabled={masterPasswordInputDisabled}
            onCheckboxChange={() => {
              setMasterPasswordChecked(!masterPasswordChecked);
              setMasterPasswordInputDisabled(masterPasswordChecked);
            }}
            onInputChange={(e) => setMasterPasswordValue(e.target.value)}
          />
          <br />
          You must set a user password to enable setting a master password.
          <br />
          <br />
          *Changes will be reflected when you save the settings and download the
          document
        </div>

        <div className='button-container security-button-container'>
          <button className='btn-primary' onClick={onSaveClick}>
            Save
          </button>
          <button className='btn-secondary' onClick={onCloseClick}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecuritySettingsModal;
