import React from 'react';
import './MobileAddButton.css';

import AddIcon from '@mui/icons-material/Add';

const MobileAddButton = ({
  currentPDFDoc,
  setShowCreateObjectModal,
  current,
  isTrailerView,
  modalInView,
}) => {
  const showButton =
    !modalInView && isTrailerView && current != 'Trailer' && currentPDFDoc;

  return (
    <div>
      {showButton && (
        <div
          className='mobile-add-btn'
          onClick={() => setShowCreateObjectModal(true)}
        >
          <AddIcon
            style={{ fill: 'white', transform: 'scale(1.5)', margin: 'auto' }}
          />
        </div>
      )}
    </div>
  );
};

export default MobileAddButton;
