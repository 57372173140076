import React, { useEffect, useState } from 'react';
import { convertTypeToStr } from '../../util';
import PDFNetObjTypes from '../../constants/PDFNetObjTypes';
import { FormControl, Select, MenuItem, Checkbox } from '@mui/material';
import './CreateModal.css';
import AddStreamButton from '../AddStreamButton';
import Placeholder from '../UI/Placeholder';

const CreateModal = ({
  setShowCreateObjectModal,
  setObjectChanged,
  currentObj,
  currentPDFDoc,
}) => {
  const [value, setValue] = useState('');
  const [key, setKey] = useState('');
  const [type, setType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [invalidValue, setInvalidValue] = useState(false);
  const [invalidKey, setInvalidKey] = useState(true);
  const [newIndex, setNewIndex] = useState(0);

  const {
    e_dict,
    e_array,
    e_number,
    e_bool,
    e_string,
    e_stream,
    e_name,
    e_null,
  } = PDFNetObjTypes;

  const disabledClass = invalidValue || invalidKey ? 'disabled' : '';
  const objCount = currentObj.children.length;
  const indexNumbers = Array.from(Array(objCount + 1).keys());
  const typeNotSelected = type === '';
  const showIndexDropdown = currentObj.type === e_array;
  const isValidContent = type !== '' && !invalidValue && !invalidKey;
  const doNotRenderTextArea =
    type !== null && type !== e_array && type !== e_dict;

  useEffect(() => {
    validateKey(key);
    validateValue(value);
  }, [type, key, value, selectedFile]);

  const handleSelectFile = async (source) => {
    setSelectedFile(source);
    setSelectedFileName(source.name);
  };

  const clickHandler = async () => {
    let newObject;
    if (currentObj.type === e_dict) {
      newObject = await currentObj.objWrapper.addToDict(
        key,
        value,
        selectedFile,
        currentPDFDoc,
        type
      );
    } else if (currentObj.type === e_array) {
      newObject = await currentObj.objWrapper.addToArray(
        value,
        selectedFile,
        currentPDFDoc,
        type,
        newIndex
      );
    }
    setObjectChanged(newObject);
  };

  const validateKey = (keyName) => {
    if (currentObj.type !== e_array) {
      const isEmptyKey = keyName.split(' ').join('') === '';
      setInvalidKey(isEmptyKey);
    } else {
      setInvalidKey(false);
    }
  };

  const renderKeyOrIndex = () => {
    if (typeNotSelected) return;

    if (showIndexDropdown) {
      return (
        <div className='index-container'>
          Index of the array the new object will be inserted to
          <FormControl sx={{ ml: 4, mt: -1 }}>
            <Select
              value={newIndex}
              onChange={(e) => setNewIndex(e.target.value)}
              size='small'
              defaultValue=''
              style={{
                minWidth: 100,
              }}
            >
              {indexNumbers.map((index) => (
                <MenuItem key={index} value={index}>
                  {index}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    }
    return (
      <input
        type='text'
        id='create-key-input'
        className='keyname-input'
        value={key}
        onChange={(e) => {
          setKey(e.target.value);
        }}
        placeholder='Enter key'
      />
    );
  };

  const getAddStreamButtonText = () =>
    selectedFileName !== '' ? selectedFileName : 'Open File';

  const validateValue = (val) => {
    if (type === e_name || type === e_string) {
      const isEmptyVal = val.split(' ').join('') === '';
      setInvalidValue(isEmptyVal);
    } else if (type === e_number) {
      const isValueNumber = parseInt(val, 10);
      setInvalidValue(isNaN(isValueNumber));
    } else if (type === e_stream) {
      setInvalidValue(!selectedFile);
    } else {
      setInvalidValue(false);
    }
  };

  const renderValue = () => {
    if (!type || type === e_null) return;
    if (type === e_stream) {
      return (
        <div className='value-wrapper'>
          <AddStreamButton
            onFileSelect={(e) => {
              handleSelectFile(e.target.files[0]);
            }}
          >
            {getAddStreamButtonText()}
          </AddStreamButton>
        </div>
      );
    }
    if (type === e_bool) {
      return (
        <div className='value-wrapper'>
          <Checkbox
            checked={value === true}
            onChange={async () => {
              setValue(!value);
            }}
            style={{
              color: '#14213d',
            }}
          />
        </div>
      );
    }

    if (doNotRenderTextArea)
      return (
        <textarea
          className='modal-textarea create-textarea'
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          placeholder='Enter value'
        />
      );
  };

  const renderTypeDropdown = () => {
    return (
      <FormControl fullWidth>
        <Select
          value={type}
          onChange={(e) => setType(e.target.value)}
          size='small'
          displayEmpty
          renderValue={
            typeNotSelected
              ? () => <Placeholder>Select the object type</Placeholder>
              : () => <div>{convertTypeToStr(type)}</div>
          }
        >
          <MenuItem value={e_array}>{convertTypeToStr(e_array)}</MenuItem>
          <MenuItem value={e_bool}>{convertTypeToStr(e_bool)}</MenuItem>
          <MenuItem value={e_dict}>{convertTypeToStr(e_dict)}</MenuItem>
          <MenuItem value={e_name}>{convertTypeToStr(e_name)}</MenuItem>
          <MenuItem value={e_null}>{convertTypeToStr(e_null)}</MenuItem>
          <MenuItem value={e_number}>{convertTypeToStr(e_number)}</MenuItem>
          <MenuItem value={e_stream}>{convertTypeToStr(e_stream)}</MenuItem>
          <MenuItem value={e_string}>{convertTypeToStr(e_string)}</MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <div className='modal-wrapper'>
      <div className='modal create-modal'>
        <div className='object-container'>
          {renderTypeDropdown()}
          {renderKeyOrIndex()}
          {renderValue()}
        </div>

        <div className='button-container standard-button-container'>
          <button
            className={`btn-primary ${disabledClass}`}
            onClick={async () => {
              if (isValidContent) {
                await clickHandler();
                setShowCreateObjectModal(false);
              }
            }}
          >
            Add
          </button>
          <button
            className='btn-secondary'
            onClick={() => setShowCreateObjectModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
