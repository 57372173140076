import './PDFObjList.css';
import { defaultView } from '../App';
import PDFObjListItem from './PDFObjListItem';
export default function PDFObjList({
  currentObj,
  items,
  onChildClick,
  openModal,
  setObjectChanged,
  userSelectedObject,
  setUserSelectedObject,
  setUserSelectedObjectIndex,
  currentTab,
  hasPointerFine,
  userSelectedObjectIndex,
  isStreamFromXref,
}) {
  const isAnnotationsList = currentObj && currentObj.key === 'Annots';
  const isFieldsList = currentObj && currentObj.key === 'Fields';
  const hideClass = currentTab === defaultView ? '' : 'hide';

  return (
    <div className={`pdf-obj-list ${hideClass}`}>
      <header>
        <div className='col icon-col'></div>
        <div className='col key-col'>Key</div>
        <div className='col type-col'>Type</div>
        <div className='col value-col'>Value</div>
      </header>
      {items.map((child, index) => (
        <PDFObjListItem
          key={child.key}
          index={index}
          isAnnotation={isAnnotationsList}
          isField={isFieldsList}
          child={child}
          onClick={onChildClick}
          openModal={openModal}
          setObjectChanged={setObjectChanged}
          userSelectedObject={userSelectedObject}
          setUserSelectedObject={setUserSelectedObject}
          setUserSelectedObjectIndex={setUserSelectedObjectIndex}
          isStreamFromXref={isStreamFromXref}
          hasPointerFine={hasPointerFine}
          userSelectedObjectIndex={userSelectedObjectIndex}
        />
      ))}
    </div>
  );
}
