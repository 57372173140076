import React from 'react';
import './FloatingToolbar.css';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';

const FloatingToolbar = ({
  deleteObject,
  disableDeleteConfirmModal,
  setShowDeleteConfirmModal,
  setShowModal,
  hasPointerFine,
}) => {
  return (
    <div className='toolbar'>
      <div className='toolbar-btn-container'>
        <Button
          variant='contained'
          startIcon={<EditIcon />}
          id='edit-btn'
          onClick={() => setShowModal(true)}
          style={{
            backgroundColor: '#14213d',
          }}
        >
          Edit
        </Button>
        <Button
          variant='outlined'
          startIcon={<DeleteIcon />}
          style={{
            backgroundColor: 'white',
            color: '#14213d',
            borderColor: '#14213d',
          }}
          onClick={async () => {
            if (disableDeleteConfirmModal || !hasPointerFine) {
              await deleteObject();
            } else {
              setShowDeleteConfirmModal(true);
            }
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default FloatingToolbar;
