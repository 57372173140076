import './Navigation.css';
import { PageDropdown } from '../Components';
import Tooltip from './UI/Tooltip';

export default function Navigation({
  onOpenClick,
  onDownloadClick,
  onBackClick,
  onNextClick,
  onRootClick,
  pageCount,
  onPageChange,
  fileName,
  onSaveAs,
  onSave,
  onSettingsClick,
  disableSecuritySettings,
  page,
  setPage,
}) {
  const disabledClass = disableSecuritySettings ? 'disabled' : '';

  return (
    <div className='nav-container'>
      <Tooltip content='Open a document to start editing'>
        <div className='btn clickable' onClick={onOpenClick}>
          Open
        </div>
      </Tooltip>
      <Tooltip content='Save and download the document in the downloads directory'>
        <div className='btn clickable' onClick={onDownloadClick}>
          Download Document
        </div>
      </Tooltip>
      <Tooltip content='Save and overwrite an existing file in your directory'>
        <div className='btn clickable' onClick={onSave}>
          Save
        </div>
      </Tooltip>
      <Tooltip content='Save and download the document in a directory of your choosing'>
        <div className='btn clickable' onClick={onSaveAs}>
          Save As
        </div>
      </Tooltip>

      <div className='vr'></div>
      <Tooltip content='Navigate back'>
        <div className='btn clickable' onClick={onBackClick}>
          Back
        </div>
      </Tooltip>
      <Tooltip content='Navigate forward'>
        <div className='btn clickable' onClick={onNextClick}>
          Next
        </div>
      </Tooltip>
      <Tooltip content='Go to the root of a document'>
        <div className='btn clickable' onClick={onRootClick}>
          Root
        </div>
      </Tooltip>
      <PageDropdown
        pageCount={pageCount}
        onChange={onPageChange}
        page={page}
        setPage={setPage}
      />
      {fileName && <div className='btn'>{fileName}</div>}
      <div
        className={`btn clickable ${disabledClass}`}
        onClick={() => {
          if (!disabledClass) onSettingsClick();
        }}
      >
        Document Security Settings
      </div>
    </div>
  );
}
