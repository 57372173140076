import PDFNetObjTypes from '../constants/PDFNetObjTypes';

const convertTypeToStr = (type) => {
  if (type === PDFNetObjTypes.e_null) {
    return 'null';
  } else if (type === PDFNetObjTypes.e_bool) {
    return 'bool';
  } else if (type === PDFNetObjTypes.e_number) {
    return 'number';
  } else if (type === PDFNetObjTypes.e_name) {
    return 'name';
  } else if (type === PDFNetObjTypes.e_string) {
    return 'string';
  } else if (type === PDFNetObjTypes.e_dict) {
    return 'dict';
  } else if (type === PDFNetObjTypes.e_array) {
    return 'array';
  } else if (type === PDFNetObjTypes.e_stream) {
    return 'stream';
  }
};

export default convertTypeToStr;
