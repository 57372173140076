import React, { useEffect, useState } from 'react';
import { previewView } from '../App';
import './PDFPreview.css';

const PDFPreview = ({
  currentTab,
  previewDoc,
  page,
  currentPDFDoc,
  objectChanged,
}) => {
  const [src, setSrc] = useState('');
  const hideClass = currentTab === previewView ? '' : 'hide';
  const loadPreview = async (previewDoc) => {
    const draw = await window.Core.PDFNet.PDFDraw.create();

    const uint8array = await draw.exportBuffer(
      await previewDoc.getPage(Number(page || 1))
    );
    const src = URL.createObjectURL(
      new Blob([uint8array.buffer], { type: 'image/png' })
    );
    setSrc(src);
  };

  useEffect(() => {
    if (previewDoc) {
      loadPreview(previewDoc);
    }
  }, [previewDoc, currentPDFDoc, page, objectChanged]);

  return (
    <div className={`preview-container ${hideClass}`}>
      <img className='preview-img' src={src} />
    </div>
  );
};

export default PDFPreview;
