import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import './ConfirmDeleteModal.css';

const ConfirmDeleteModal = ({
  onDeleteConfirmation,
  setShowDeleteConfirmModal,
  deleteConfirmModalChecked,
  setDeleteConfirmModalChecked,
}) => {
  return (
    <div className='modal-wrapper'>
      <div className='modal delete-modal'>
        <div className='title'>Delete Key</div>
        <div className='paddingContainer message'>
          Are you sure you want to delete this item?
          <br />
          <div id='show-modal-checkbox'>
            <FormControlLabel
              label='Do not show this message again'
              control={
                <Checkbox
                  checked={deleteConfirmModalChecked}
                  onChange={() => {
                    setDeleteConfirmModalChecked(!deleteConfirmModalChecked);
                  }}
                  style={{
                    color: '#14213d',
                  }}
                />
              }
            />
          </div>
        </div>
        <div className='button-container delete-modal-button-container'>
          <button
            className='btn-primary'
            onClick={() => onDeleteConfirmation(deleteConfirmModalChecked)}
          >
            Delete
          </button>
          <button
            className='btn-secondary'
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
