import React from 'react';
import './NoDocumentMsg.css';

const NoDocumentMsg = () => {
  return (
    <div className='no-doc-msg'>
      <h2>No document opened yet.</h2>
      <h4> Please select a document to open.</h4>
    </div>
  );
};

export default NoDocumentMsg;
