import React from 'react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';

const Tooltip = ({ content, children }) => {
  return (
    <Tippy
      className='tooltip'
      content={content}
      placement='bottom'
      animation='scale-subtle'
      duration={200}
      delay={[75, 0]}
      distance={8}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
