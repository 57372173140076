import PDFObject from './PDFObject';

const loadChild = async (pdfnetObj, parent) => {
  const isIndirect = await pdfnetObj.isIndirect();
  const objnum = await pdfnetObj.getObjNum();
  const gennum = await pdfnetObj.getGenNum();
  const isFree = await pdfnetObj.isFree();
  let type;

  let value;
  if ((isFree && parent && parent.key === 'Trailer') || !isFree) {
    type = await pdfnetObj.getType();

    const objWrapper = new PDFObject(pdfnetObj, type);
    const value = await objWrapper.getValue();

    return {
      value,
      type,
      isIndirect,
      objnum,
      gennum,
    };
  }
  return {
    value,
    type: '-',
    isIndirect,
    objnum,
    gennum,
  };
};

const loadArr = async (parent, pdfnetObj) => {
  const children = [];
  const arrLength = await pdfnetObj.size();
  for (let i = 0; i < arrLength; i++) {
    const obj = await pdfnetObj.getAt(i);
    const childData = await loadChild(obj);
    const objWrapper = new PDFObject(obj, childData.type);
    children.push({
      key: i,
      ...childData,
      obj,
      parent,
      objWrapper,
    });
  }
  return children;
};

const loadDict = async (parent, pdfnetObj) => {
  const children = [];
  const itr = await pdfnetObj.getDictIterator();
  while (await itr.hasNext()) {
    const key = await (await itr.key()).getName();
    const obj = await itr.value();
    const childData = await loadChild(obj, parent);
    const objWrapper = new PDFObject(obj, childData.type);

    children.push({
      key,
      ...childData,
      obj,
      parent,
      objWrapper,
    });
    await itr.next();
  }
  return children;
};

const loadChildren = async (parent) => {
  if (!parent || !parent.obj) {
    return [];
  }
  const pdfnetObj = parent.obj;
  const isArray = await pdfnetObj.isArray();
  if (isArray) {
    const children = await loadArr(parent, pdfnetObj);
    return children;
  } else {
    const children = await loadDict(parent, pdfnetObj);
    return children;
  }
};

const loadDictOrArrChildren = async (parent) => {
  if (!parent || !parent.obj) {
    return [];
  }
  const pdfnetObj = parent.obj;
  const isArray = await pdfnetObj.isArray();
  const isDict = await pdfnetObj.isDict();
  if (isArray) {
    const children = await loadArr(parent, pdfnetObj);
    return children;
  } else if (isDict) {
    const children = await loadDict(parent, pdfnetObj);
    return children;
  }
  return;
};

export { loadChild, loadChildren, loadDictOrArrChildren };
