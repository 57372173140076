import BreadcrumbSibling from './BreadcrumbSibling';
import { randomIntFromInterval } from '../util';

export default function Breadcrumb({ child, onClick }) {
  return (
    <div className='dropdown'>
      <div
        className='btn clickable'
        key={child.key}
        onClick={onClick.bind(this, child)}
      >
        {child.key}
      </div>
      {child.sameLevel && (
        <div className='dropdown-content'>
          {child.sameLevel.map((sibling) => (
            <BreadcrumbSibling
              key={randomIntFromInterval(10000, 100000)}
              child={sibling}
              onClick={onClick}
            />
          ))}
        </div>
      )}
    </div>
  );
}
