import { useState, useEffect } from 'react';
import { convertTypeToStr, loadDictOrArrChildren } from '../util';
import PDFNetObjTypes from '../constants/PDFNetObjTypes';
import { Checkbox } from '@mui/material';
export default function PDFObjListItem({
  child,
  onClick,
  isAnnotation,
  isField,
  index,
  userSelectedObject,
  setUserSelectedObject,
  setUserSelectedObjectIndex,
  hasPointerFine,
  userSelectedObjectIndex,
}) {
  const [extraKeyData, setExtraKeyData] = useState(null);
  const [selected, setSelected] = useState(false);
  const [showRadio, setShowRadio] = useState(true);

  const { e_array, e_dict, e_stream } = PDFNetObjTypes;

  const selectedClass =
    selected && userSelectedObjectIndex === index ? `selected` : ``;

  useEffect(() => {
    const isItemSelected = userSelectedObject === child;
    if (hasPointerFine) {
      if (!isItemSelected) {
        setSelected(false);
        setShowRadio(false);
      }
    } else {
      setShowRadio(true);
      setSelected(isItemSelected);
    }
  }, [userSelectedObject, child, hasPointerFine]);

  useEffect(() => {
    if (isAnnotation || isField) {
      async function getData() {
        const data = await loadDictOrArrChildren(child);
        if (isAnnotation && data) {
          const subtype = data.find((element) => element.key === 'Subtype');
          if (subtype) {
            setExtraKeyData(subtype.value);
          }
        } else if (isField && data) {
          const fieldName = data.find((element) => element.key === 'T');
          if (fieldName) {
            setExtraKeyData(fieldName.value);
          }
        }
      }
      getData();
    }
  }, [isAnnotation, isField, child]);

  const typesThatCanHaveChildren =
    child.type === e_array || child.type === e_dict || child.type === e_stream;
  const isClickable =
    !userSelectedObject && child.key !== 'DATA' && typesThatCanHaveChildren;
  const clickableClass = isClickable ? 'clickable' : '';
  const onClickHandler = isClickable ? onClick.bind(this, child) : () => {};

  const objNumData = child.isIndirect
    ? `obj: ${child.objnum} gen: ${child.gennum}`
    : '';

  const renderValue = () => {
    if (typeof child.value == 'boolean' || typeof child.value == 'number')
      return String(child.value);

    if (!child.value && !objNumData) return '-';

    const valueNeedsTruncating = String(child.value).length > 20;
    if (!hasPointerFine && valueNeedsTruncating) {
      let truncatedValue = String(child.value).substring(0, 20);
      return `${truncatedValue}...`;
    } else if (valueNeedsTruncating) {
      let truncatedValue = String(child.value).substring(0, 40);
      return `${truncatedValue}...`;
    }

    return `${child.value ? child.value : ''}${objNumData}`;
  };

  let displayKey = child.key;
  if (extraKeyData) {
    displayKey += ` \u00A0\u00A0[${extraKeyData}]`;
  }

  const renderType = () => {
    const typeIsNotSelected = child.type === '-';
    if (typeIsNotSelected) return <div className='col type-col'>-</div>;

    return (
      <div className='col type-col'>
        {convertTypeToStr(child.type)} {child.isIndirect ? 'link' : ''}
      </div>
    );
  };

  const showSelectCheckbox = () => {
    setSelected(!selected);
    if (selected) {
      setUserSelectedObject(null);
      setUserSelectedObjectIndex(-1);
    } else {
      setUserSelectedObject(child);
      setUserSelectedObjectIndex(index);
    }
  };
  return (
    <div
      className={`row ${selectedClass}`}
      onMouseEnter={() => {
        setShowRadio(true);
      }}
      onMouseLeave={() => {
        if (userSelectedObject !== child) setShowRadio(false);
      }}
    >
      <div className='col icon-col'>
        <div className='radio-select'>
          {showRadio && (
            <Checkbox
              checked={selected}
              onChange={showSelectCheckbox}
              size='small'
              sx={{ mt: -0.5, ml: 1.5 }}
              style={{
                color: '#14213d',
              }}
            />
          )}
        </div>
      </div>
      <div
        className={`col key-col ${clickableClass}`}
        onClick={() => {
          if (!userSelectedObject) onClickHandler();
        }}
      >
        {displayKey}
      </div>
      {renderType()}
      <div className='col value-col'>{renderValue()}</div>
    </div>
  );
}
