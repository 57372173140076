import React, { useEffect, useState, useCallback } from 'react';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import { loadChild, loadChildren } from './util';
import './App.css';
import { Navigation, Breadcrumbs, PDFObjList } from './Components';
import ViewModal from './Components/Modal/ViewModal';
import ConfirmDeleteModal from './Components/Modal/ConfirmDeleteModal';
import PDFNetObjTypes from './constants/PDFNetObjTypes';
import FloatingToolbar from './Components/UI/FloatingToolbar';
import SecuritySettingsModal from './Components/Modal/SecuritySettingsModal';
import CreateModal from './Components/Modal/CreateModal';
import NoDocumentMsg from './Components/UI/NoDocumentMsg';
import ViewTab from './Components/UI/ViewTab';
import XRefTable from './Components/XRefTable';
import PDFObject from './util/PDFObject';
import PDFPreview from './Components/PDFPreview';
import MobileNav from './Components/MobileComponent/MobileNav';
import MobileAddButton from './Components/MobileComponent/MobileAddButton';

window.Core.setWorkerPath('/webviewer/lib/core');
window.Core.enableFullPDF();

const setDisableDeleteConfirmModalInitialState = () => {
  try {
    if (localStorage.getItem('disableShowingConfirmDeletionModal') !== null) {
      return localStorage.getItem('disableShowingConfirmDeletionModal');
    }
  } catch (e) {
    console.log(e);
  }

  return false;
};

export const defaultView = 'Trailer View';
export const xRefView = 'XRefTable';
export const previewView = 'Preview';
export const maxXrefObjectPerPage = 250;

function App() {
  const [trailer, setTrailer] = useState(null);
  const [currentPDFDoc, setPDFDoc] = useState(null);
  const [fileName, setFileName] = useState('');
  const [pageCount, setPageCount] = useState(null);
  const [currentObj, setCurrentObj] = useState(null);
  const [children, setChildren] = useState([]);
  const [objPath, setObjPath] = useState([]);
  const [history, setHistory] = useState([]);
  const [future, setFuture] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [objectChanged, setObjectChanged] = useState(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [isFDF, setIsFDF] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [fileHandler, setFileHandler] = useState(null);
  const [userSelectedObject, setUserSelectedObject] = useState(null);
  const [userSelectedObjectIndex, setUserSelectedObjectIndex] = useState(-1);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [disableDeleteConfirmModal, setDisableDeleteConfirmModal] =
    useState(false);
  const [deleteConfirmModalChecked, setDeleteConfirmModalChecked] =
    useState(false);
  const [showCreateObjectModal, setShowCreateObjectModal] = useState(false);
  const [page, setPage] = useState('');
  const { e_array, e_dict, e_stream } = PDFNetObjTypes;
  const [disableSecuritySettings, setDisableSecuritySettings] = useState(false);
  const [xrefData, setXrefData] = useState([]);
  const [currentTab, setCurrentTab] = useState(defaultView);
  const [previewDoc, setPreviewDoc] = useState(null);
  const [isStreamFromXref, setIsStreamFromXref] = useState(false);
  const [xrefJSObj, setXrefJSObj] = useState(null);
  const [enableXrefPagination, setEnableXrefPagination] = useState(false);
  const [hasPointerFine, setHasPointerFine] = useState(true);

  const showFloatingToolbar = userSelectedObject && currentTab === defaultView;
  const modalInView =
    showModal ||
    showCreateObjectModal ||
    showDeleteConfirmModal ||
    showSettingsModal ||
    showFloatingToolbar;

  const openSecureDoc = async (securedDoc, password) => {
    if (password.length > 0) {
      if (await securedDoc.initStdSecurityHandlerUString(password)) {
        console.log('The password is correct');
      } else {
        const newPassword = window.prompt(
          'The password is incorrect. Please try again.'
        );
        await openSecureDoc(securedDoc, newPassword);
      }
    }

    const securityHandler = await securedDoc.getSecurityHandler();

    const owner = await securityHandler.getPermission(
      await window.PDFNet.SecurityHandler.Permission.e_owner
    );
    setIsOwner(owner);
    await securedDoc.removeSecurity();
    const docbuf = await securedDoc.saveMemoryBuffer(
      await window.PDFNet.SDFDoc.SaveOptions.e_remove_unused
    );
    setPDFDoc(docbuf);
  };

  useEffect(() => {
    if (currentPDFDoc) {
      loadXrefData();
    }
    setUserSelectedObject(null);
  }, [currentPDFDoc]);

  useEffect(() => {
    setHasPointerFine(matchMedia('(pointer:fine)').matches);
  }, []);

  const onXRefObjectClick = async (selectedXRefObj, objNum) => {
    setCurrentTab(defaultView);
    setUserSelectedObject(null);

    const childData = await loadChild(selectedXRefObj);

    const objWrapper = new PDFObject(selectedXRefObj, e_stream);
    const obj = {
      ...childData,
      key: objNum,
      obj: selectedXRefObj,
      parent: null,
      objWrapper,
    };

    setIsStreamFromXref((await selectedXRefObj.getType()) === e_stream);

    setXrefJSObj(obj);
    setCurrentObj(obj);
  };

  const loadXrefData = async () => {
    const sdfDoc = await currentPDFDoc.getSDFDoc();
    const size = await sdfDoc.xRefSize();
    const data = [];
    for (let i = 0; i < size; i++) {
      const obj = await sdfDoc.getObj(i);
      data.push(obj);
    }
    setEnableXrefPagination(data.length > maxXrefObjectPerPage);
    setXrefData(data);
  };

  const loadDocument = async (source) => {
    if (!source) {
      return;
    }

    let fileName = source;
    if (source instanceof File) {
      fileName = source.name;
    }
    fileName = fileName.split('/').slice(-1)[0];

    const extension = fileName.split('.').slice(-1)[0].toLowerCase();

    let pdfDoc;
    let pageCount;
    const IsFDFExtension = extension === 'fdf';

    if (IsFDFExtension) {
      const buffer = await source.arrayBuffer();
      pdfDoc = await window.Core.PDFNet.FDFDoc.createFromMemoryBuffer(buffer);
      pageCount = 0;
      setIsOwner(true);
      setDisableSecuritySettings(true);
    } else if (source instanceof File) {
      const buffer = await source.arrayBuffer();
      pdfDoc = await window.Core.PDFNet.PDFDoc.createFromBuffer(buffer);

      const securityHandlerInit = await pdfDoc.initSecurityHandler();
      if (!securityHandlerInit) {
        const password = await window.prompt(
          'This document is password protected. Please enter the password below.'
        );
        await openSecureDoc(pdfDoc, password);
      }

      pageCount = await pdfDoc.getPageCount();
      setDisableSecuritySettings(false);
      setPreviewDoc(pdfDoc);
    } else {
      const doc = await window.Core.createDocument(source);
      pdfDoc = await doc.getPDFDoc();
      pageCount = await pdfDoc.getPageCount();
      setIsOwner(true);
      setDisableSecuritySettings(false);
      setPreviewDoc(pdfDoc);
    }

    console.log('PDF Document Loaded');

    const root = await pdfDoc.getTrailer();
    const childData = await loadChild(root);

    const trailerObj = {
      key: 'Trailer',
      ...childData,
      obj: root,
      parent: null,
    };
    setIsFDF(IsFDFExtension);
    setCurrentObj(trailerObj);
    setPage('');
    setTrailer(trailerObj);
    setPDFDoc(pdfDoc);
    setPageCount(pageCount);
    setFileName(fileName);
  };

  useEffect(() => {
    window.PDFNet.initialize(
      atob(
        'UERGVHJvbjpXRUJDUFU6MTo6Qis6QU1TKDIwMjIwOTEzKTo4Mzk0QzIxQzQ5QUNBMDg3N0RCQ0ZBRTEzRkY2QzAwMDlFRDY0MEQyODExMTMxRjVDNw=='
      )
    ).then(() => {
      loadDocument('http://localhost:3000/allforms.pdf');
    });
  }, []);

  useEffect(() => {
    const initialDisableDeletionState =
      setDisableDeleteConfirmModalInitialState();
    setDisableDeleteConfirmModal(initialDisableDeletionState == 'true');
  }, []);

  useEffect(() => {
    if (!currentObj) {
      return;
    }
    loadChildren(currentObj).then(async (children) => {
      currentObj.children = children;
      children.forEach((child) => (child.sameLevel = children));
      const insertDataInTrailerView =
        currentObj.type === e_stream && currentObj.key !== 'Trailer';

      if (isStreamFromXref) {
        const contentObj = {
          ...xrefJSObj,
          children: null,
          key: 'DATA',
        };
        if (xrefJSObj && xrefJSObj.children) {
          const childrenWithStreamContent = [contentObj, ...xrefJSObj.children];
          setChildren(childrenWithStreamContent);
        }
      } else if (insertDataInTrailerView) {
        const objWrapper = new PDFObject(currentObj.obj, e_stream);
        const contentObj = {
          ...currentObj,
          children: null,
          parent: currentObj,
          key: 'DATA',
          objWrapper,
        };
        const childrenWithStreamContent = [contentObj, ...children];
        setChildren(childrenWithStreamContent);
      } else {
        setChildren(children);
      }
    });
    let path = [currentObj];
    let current = currentObj;
    while (current.parent) {
      path.unshift(current.parent);
      current = current.parent;
    }
    setObjPath(path);
  }, [currentObj, objectChanged, isStreamFromXref, xrefJSObj]);

  useEffect(() => {
    setIsStreamFromXref(false);
  }, [currentPDFDoc]);

  const onChildClick = function (child) {
    const pathIdx = objPath.findIndex(
      (pathItem) =>
        pathItem.objnum === child.objnum &&
        pathItem.gennum === child.gennum &&
        (child.isIndirect || pathItem.key === child.key)
    );
    if (pathIdx >= 0) {
      const existingItem = objPath[pathIdx];
      existingItem.previous = currentObj;
      setCurrentObj(existingItem);
    } else {
      setCurrentObj(child);
    }
    setPage('');
    setIsStreamFromXref(false);
    setHistory([...history, child]);
    setFuture([]);
    setUserSelectedObject(null);
    setIsStreamFromXref(false);
  };

  const onCloseClick = function () {
    setShowModal(false);
  };

  const navigateToRoot = () => onChildClick(trailer);
  const navigateBack = () => {
    if (history.length === 0) {
      return;
    }
    const futureItem = history.pop();
    setHistory(history.slice());
    setFuture([...future, futureItem]);
    setCurrentObj(history[history.length - 1] || trailer);
    setPage('');
    setIsStreamFromXref(false);
  };
  const navigateNext = () => {
    if (future.length === 0) {
      return;
    }
    const historyItem = future.pop();
    setFuture(future.slice());
    setHistory([...history, historyItem]);
    setCurrentObj(historyItem);
    setPage('');
    setIsStreamFromXref(false);
  };

  const onOpenClick = async () => {
    if (!window.showOpenFilePicker) {
      // https://stackoverflow.com/questions/69118076/window-showopenfilepicker-polyfill
      window.showOpenFilePicker = () => {
        return new Promise((resolve) => {
          const input = document.createElement('input');
          input.type = 'file';
          input.multiple = false;
          input.accept = '.pdf';
  
          input.addEventListener('change', () => {
              resolve(
                  [...input.files].map((file) => {
                      return {
                          getFile: async () =>
                              new Promise((resolve) => {
                                  resolve(file);
                              }),
                      };
                  })
              );
          });
  
          input.click();
        });
      };
    }

    const [fileHandle] = await window.showOpenFilePicker({ multiple: false });
    setFileHandler(fileHandle);
    const file = await fileHandle.getFile();

    await loadDocument(file);
  };

  const onDownloadClick = async () => {
    if (currentPDFDoc) {
      const blob = await getCurrentFileContentsForSaving();
      if (isFDF) {
        saveAs(blob, 'download.fdf');
      } else {
        saveAs(blob, 'download.pdf');
      }
    }
  };

  const writeFile = async (fileHandle, contents) => {
    const writable = await fileHandle.createWritable();
    await writable.write(contents);
    await writable.close();
  };

  const getCurrentFileContentsForSaving = async () => {
    const fileData = await currentPDFDoc.saveMemoryBuffer(
      window.PDFNet.SDFDoc.SaveOptions.e_remove_unused
    );
    return new Blob([fileData], { type: 'application/pdf' });
  };

  const onSave = async () => {
    const blob = await getCurrentFileContentsForSaving();
    await writeFile(fileHandler, blob);
  };

  const onSaveAs = async () => {
    const options = {
      types: [
        {
          description: 'Document Files',
          accept: {
            'application/pdf': isFDF ? ['.fdf'] : ['.pdf'],
          },
          startIn: 'Downloads',
          multiple: false,
        },
      ],
    };
    const handle = await window.showSaveFilePicker(options);
    const blob = await getCurrentFileContentsForSaving();
    await writeFile(handle, blob);
  };

  const onPageChange = async (e) => {
    const page = parseInt(e.target.value, 10);
    if (isNaN(page)) {
      return;
    }

    const itr = await currentPDFDoc.getPageIterator(page);
    const pdfPage = await itr.current();
    const pageObj = await pdfPage.getSDFObj();

    const childData = await loadChild(pageObj);

    const obj = {
      key: page,
      ...childData,
      obj: pageObj,
      parent: null,
    };

    setCurrentObj(obj);
    setUserSelectedObject(null);
    setUserSelectedObjectIndex(-1);
    setIsStreamFromXref(false);
  };

  const onDrop = useCallback((files) => {
    if (files.length > 0) {
      loadDocument(files[0]);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    noClick: true,
    accept: ['application/pdf'],
  });

  const onDeleteConfirmation = (doNotShowAgain) => {
    setDisableDeleteConfirmModal(doNotShowAgain);
    try {
      window.localStorage.setItem(
        'disableShowingConfirmDeletionModal',
        doNotShowAgain
      );
    } catch (e) {}
    setShowDeleteConfirmModal(false);
    deleteObject();
  };

  const deleteObject = async () => {
    let deletedObject;
    if (userSelectedObject.parent.type === e_array) {
      deletedObject = await userSelectedObject.obj;
      await userSelectedObject.parent.obj.eraseAt(userSelectedObjectIndex);
    } else if (userSelectedObject.parent.type === e_dict) {
      deletedObject = await userSelectedObject.obj;
      await userSelectedObject.parent.obj.eraseFromKey(
        String(userSelectedObject.key)
      );
    }
    setObjectChanged(deletedObject);
    setUserSelectedObject(null);
    setUserSelectedObjectIndex(-1);
    setShowSettingsModal(false);
  };

  const openSettingsModal = () => {
    setShowSettingsModal(true);
    setShowModal(false);
  };

  const renderModals = () => {
    return (
      <React.Fragment>
        {showFloatingToolbar && (
          <FloatingToolbar
            deleteObject={deleteObject}
            disableDeleteConfirmModal={disableDeleteConfirmModal}
            setShowDeleteConfirmModal={setShowDeleteConfirmModal}
            setShowModal={setShowModal}
            hasPointerFine={hasPointerFine}
          />
        )}
        {showModal && (
          <ViewModal
            onCloseClick={onCloseClick}
            userSelectedObject={userSelectedObject}
            setObjectChanged={setObjectChanged}
            setUserSelectedObject={setUserSelectedObject}
            currentPDFDoc={currentPDFDoc}
            currentObj={currentObj}
            userSelectedObjectIndex={userSelectedObjectIndex}
            isStreamFromXref={isStreamFromXref}
          />
        )}
        {showCreateObjectModal && (
          <CreateModal
            setShowCreateObjectModal={setShowCreateObjectModal}
            setObjectChanged={setObjectChanged}
            currentObj={currentObj}
            currentPDFDoc={currentPDFDoc}
          />
        )}
        {showDeleteConfirmModal && !disableDeleteConfirmModal && (
          <ConfirmDeleteModal
            setShowDeleteConfirmModal={setShowDeleteConfirmModal}
            deleteConfirmModalChecked={deleteConfirmModalChecked}
            setDeleteConfirmModalChecked={setDeleteConfirmModalChecked}
            onDeleteConfirmation={onDeleteConfirmation}
          />
        )}
        {showSettingsModal && (
          <SecuritySettingsModal
            onCloseClick={() => setShowSettingsModal(false)}
            currentPDFDoc={currentPDFDoc}
            setShowSettingsModal={setShowSettingsModal}
            isOwner={isOwner}
          />
        )}
        <MobileAddButton
          currentPDFDoc={currentPDFDoc}
          setShowCreateObjectModal={setShowCreateObjectModal}
          current={currentObj && currentObj.key}
          isTrailerView={currentTab === defaultView}
          modalInView={modalInView}
        />
      </React.Fragment>
    );
  };

  return (
    <div className='App' {...getRootProps()}>
      <input {...getInputProps()} />
      <Navigation
        onOpenClick={onOpenClick}
        onDownloadClick={onDownloadClick}
        onBackClick={navigateBack}
        onNextClick={navigateNext}
        onRootClick={navigateToRoot}
        onPageChange={onPageChange}
        pageCount={pageCount}
        fileName={fileName}
        onSaveAs={onSaveAs}
        onSave={onSave}
        onSettingsClick={openSettingsModal}
        disableSecuritySettings={disableSecuritySettings}
        page={page}
        setPage={setPage}
      />
      <Breadcrumbs
        current={currentObj && currentObj.key}
        items={objPath}
        onChildClick={onChildClick}
        currentObj={currentObj}
        currentPDFDoc={currentPDFDoc}
        setObjectChanged={setObjectChanged}
        setShowCreateObjectModal={setShowCreateObjectModal}
      />
      <ViewTab
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        currentPDFDoc={currentPDFDoc}
      />
      <PDFObjList
        currentObj={currentObj}
        items={children}
        onChildClick={onChildClick}
        setObjectChanged={setObjectChanged}
        setShowDeleteConfirmModal={setShowDeleteConfirmModal}
        deleteObject={deleteObject}
        disableDeleteConfirmModal={disableDeleteConfirmModal}
        userSelectedObject={userSelectedObject}
        userSelectedObjectIndex={userSelectedObjectIndex}
        setUserSelectedObject={setUserSelectedObject}
        setUserSelectedObjectIndex={setUserSelectedObjectIndex}
        currentTab={currentTab}
        isStreamFromXref={isStreamFromXref}
        hasPointerFine={hasPointerFine}
      />
      <XRefTable
        xrefData={xrefData}
        currentTab={currentTab}
        onXRefObjectClick={onXRefObjectClick}
        enableXrefPagination={enableXrefPagination}
      />
      <PDFPreview
        currentTab={currentTab}
        previewDoc={previewDoc}
        page={page}
        currentPDFDoc={currentPDFDoc}
        objectChanged={objectChanged}
      />
      <MobileNav
        onOpenClick={onOpenClick}
        onDownloadClick={onDownloadClick}
        onBackClick={navigateBack}
        onNextClick={navigateNext}
        onSettingsClick={openSettingsModal}
      />
      {!currentPDFDoc && <NoDocumentMsg />}

      <div className='footer' />
      {renderModals()}
    </div>
  );
}

export default App;
