import React, { useEffect, useState } from 'react';
import XRefObject from './XRefObject';
import './XRefTable.css';
import { maxXrefObjectPerPage, xRefView } from '../App';
import { Pagination } from '@mui/material';

const XRefTable = ({
  xrefData,
  currentTab,
  onXRefObjectClick,
  enableXrefPagination,
}) => {
  const hideClass = currentTab === xRefView ? '' : 'hide';
  const [xrefDataPaginatedArray, setXrefDataPaginatedArray] = useState([]);
  const maxPageNum = Math.ceil(xrefData.length / maxXrefObjectPerPage);
  const [pageValuesToDisplay, setPageValuesToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setPageValuesToDisplay(xrefData.slice(0, maxXrefObjectPerPage));
  }, []);

  useEffect(() => {
    if (enableXrefPagination && xrefDataPaginatedArray.length > 1) {
      const valueInPageToDisplay = xrefDataPaginatedArray.at(currentPage - 1);
      setPageValuesToDisplay(valueInPageToDisplay);
    } else if (!enableXrefPagination && xrefData.length > 0) {
      setPageValuesToDisplay(xrefData);
    }
  }, [currentPage, enableXrefPagination, xrefDataPaginatedArray, xrefData]);

  useEffect(() => {
    if (enableXrefPagination) {
      const arr = [];
      let objectsInPage;
      for (let i = 0; i < maxPageNum; i++) {
        if (i === 0) {
          objectsInPage = xrefData.slice(0, maxXrefObjectPerPage);
        } else if (i === maxPageNum - 1) {
          objectsInPage = xrefData.slice(
            maxXrefObjectPerPage * i,
            xrefData.length + 1
          );
        } else {
          objectsInPage = xrefData.slice(
            maxXrefObjectPerPage * i,
            maxXrefObjectPerPage * (i + 1)
          );
        }
        arr.push(objectsInPage);
      }
      setXrefDataPaginatedArray(arr);
    } else {
      setXrefDataPaginatedArray([]);
    }
  }, [xrefData, enableXrefPagination]);

  return (
    <div className={`${hideClass}`}>
      <div className={`pdf-obj-list xref`}>
        <header>
          <div className='col obj-col'>Object Number</div>
          <div className='col gen-col'>Gen Number</div>
          <div className='col obj-type-col'>Type</div>
          <div className='col free-col'>Free</div>
          <div className='col offset-col'>Offset</div>
        </header>
        {pageValuesToDisplay.map((obj, index) => (
          <XRefObject
            key={index}
            object={obj}
            onXRefObjectClick={onXRefObjectClick}
          />
        ))}
      </div>
      <div>
        {enableXrefPagination && (
          <div className='pagination-btn'>
            <Pagination
              size='large'
              count={maxPageNum}
              onChange={(e, page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default XRefTable;
